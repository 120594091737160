<template>
  <div id="form-kd">
    <a-form-model :model="data" ref="ruleForm" :rules="rules">
      <a-form-model-item :colon="false" prop="no">
        <span slot="label"> No&nbsp;</span>
        <a-input-number
          :min="1"
          class="w-100"
          size="large"
          v-model="data.no"
        ></a-input-number>
      </a-form-model-item>
      <a-row :gutter="16">
        <a-col :md="24" :lg="24">
          <a-form-model-item :colon="false" prop="nama">
            <span slot="label"> Knowledge&nbsp;</span>
            <a-textarea
              :autoSize="{ minRows: 6 }"
              size="large"
              class="w-100"
              v-model="data.nama_pengetahuan"
            />
          </a-form-model-item>
        </a-col>
        <a-col :md="24" :lg="24">
          <a-form-model-item :colon="false" prop="nama">
            <span slot="label"> Skill&nbsp;</span>
            <a-textarea
              :autoSize="{ minRows: 6 }"
              size="large"
              class="w-100"
              v-model="data.nama_keterampilan"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item :colon="false" prop="semester">
        <span slot="label"> Semester&nbsp;</span>
        <a-radio-group
          size="large"
          class="w-100"
          :value="data.semester"
          @change="(e) => handleChange(e.target.value, 'semester')"
          button-style="solid"
        >
          <a-radio-button class="w-50 text-center" value="Ganjil">
            First Semester
          </a-radio-button>
          <a-radio-button class="w-50 text-center" value="Genap">
            Second Semester
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        prop="is_mid_report"
        label="Report Type"
      >
        <!-- <span slot="label"> Set to Mi&nbsp;</span> -->
        <div class="p-3 border rounded">
          <a-checkbox
            :checked="data.is_mid_report"
            @change="(e) => handleChange(e.target.checked, 'is_mid_report')"
          >
            Set to MID Report
          </a-checkbox>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    loadingValidate: {
      type: Boolean,
      required: true,
    },
    allKd: {
      type: Array,
      required: true,
    },
    currentKdEdit: {
      type: Object,
    },
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
  computed: {
    rules() {
      return {
        no: [
          { required: true, message: 'Number of KD is required!', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              const kdIsExist = this.allKd.find(kd => kd.no === value && value !== this.currentKdEdit.no)
              if (kdIsExist) callback(new Error('number of KD is exist!'))
              else callback()
            },
          },
        ],
        nama: [
          {
            validator: (rule, value, callback) => {
              if (!this.data.nama_keterampilan && !this.data.nama_pengetahuan) callback(new Error('Knowledge or Skill must be filled!'))
              else callback()
            },
          },
        ],
      }
    },
  },
  watch: {
    loadingValidate(newVal) {
      if (newVal) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit('validationForm', true)
          } else {
            this.$emit('validationForm', false)
            // this.$notification.error({
            //   message: 'Maaf',
            //   description: 'Ada data wajib yang belum terisi/sesuai. Periksa kembali.',
            // })
            // return false
          }
        })
      }
    },
  },
}
</script>

<style>
</style>
